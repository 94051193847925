import React, {useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Footer from "./Footer";
import { logPageView } from "../analytics";


const Content = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    logPageView("content");
  })

  return (
    <>
      <section className="content">
        <div className="container flex-col flex-center py-5 gap-2">
          <a href="https://github.com/mikejar">
            <div className="card grid text-dark slide-left">
              <FontAwesomeIcon icon={['fab', 'github']} className="mx-auto fa-3x"/>
              <div className="card-text">My GitHub Profile</div>
            </div>
          </a>
          <a href="https://stackoverflow.com/users/14266189/nihilok">
            <div className="card grid text-dark slide-right">
              <FontAwesomeIcon icon={['fab', 'stack-overflow']} className="mx-auto fa-3x"/>
              <div className="card-text">Stack Overflow</div>
            </div>
          </a>
          <a href="https://www.linkedin.com/in/mjfullstack/">
            <div className="card grid text-dark slide-left">
              <FontAwesomeIcon icon={['fab', 'linkedin']} className="mx-auto fa-3x"/>
              <div className="card-text">My LinkedIn</div>
            </div>
          </a>
          <a href="https://mjfullstack.medium.com/">
            <div className="card grid text-dark slide-right">
              <FontAwesomeIcon icon={['fab', 'medium']} className="mx-auto fa-3x"/>
              <div className="card-text">My Medium Profile</div>
            </div>
          </a>
        </div>
      </section>
      <Footer/>
    </>
  );
};

export default Content;