import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import cloud from "../images/clouds.png";
import Footer from "./Footer";
import { useToastContext, ADD } from "../contexts/ToastContext";
import { isIOS, isMobile } from "react-device-detect";
import { technologies } from "../data/technologies";
import { TimeSinceReadout } from "./TimeSinceReadout";
import {logPageView} from "../analytics";

const LandingPage = () => {

  useLayoutEffect(() => {
    logPageView("Home");
  }, []);

  const { toastDispatch } = useToastContext();

  const [days, setDays] = useState(0);
  const [contributions, setContributions] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [bugs, setBugs] = useState(2343265);
  const startDate = "July 1, 2020";

  const initialState = {
    name: "",
    company: "",
    email: "",
  };
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({
      ...formData,
      [name]: value,
    }));
  };

  function buildEmailMessage() {
    return `You have a new website enquiry!\n\nName: ${formData.name}\nCompany: ${formData.company}\nEmail: ${formData.email}\n\n`
  }

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    console.log(formData);
    await fetch("https://mailer.jarv.dev/send/mjf/", {
      headers: {
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify({subject: "Website Enquiry", body: buildEmailMessage()}),
    })
      .then((response) =>
        response.json().then((data) => {
          if (response.status === 200) {
            toastDispatch({
              type: ADD,
              payload: {
                content: () => <div className="">Enquiry sent successfully</div>,
                type: "success",
              },
            });
            setFormData(initialState);
          } else {
            throw new Error("There was a problem sending the enquiry. Please try again later.");
          }
        })
      )
      .catch((e) => {
        toastDispatch({
          type: ADD,
          payload: {
            content: () => <div className="">{e.message}</div>,
            type: "danger",
          },
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  async function getContributions(username) {
    fetch(`https://portfolio.jarv.dev/github_contributions/${username}/`).then(
      (response) => {
        if (response.status === 200) {
          response.json().then((data) => {
            setContributions(data.contributions);
          });
        }
      }
    );
  }

  function numberWithCommas(x) {
    if (isIOS) {
      return x;
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const getXp = useCallback(() => {
    const secondsStamp = Math.floor(
      (new Date(Date.now()).getTime() - new Date(startDate).getTime()) / 1000
    );
    setDays(Math.floor(secondsStamp / 86400));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getContributions("mikejar");
    getXp();
    const interval1 = setInterval(() => {
      getXp();
    }, 1000);
    const interval2 = setInterval(() => {
      setBugs((bugs) => bugs + 1);
    }, 100);
    const inViewport = (entries, observer) => {
      entries.forEach((entry) => {
        entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
      });
    };

    const Obs = new IntersectionObserver(inViewport);
    const obsOptions = {
      root: null,
      threshold: 0.7,
      rootMargin: "250px",
    }; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
    const ELs_inViewport = document.querySelectorAll("[data-inviewport]");
    ELs_inViewport.forEach((EL) => {
      Obs.observe(EL, obsOptions);
    });
    return () => {
      // window.removeEventListener('scroll', scrollHandler)
      clearInterval(interval1);
      clearInterval(interval2);
    };
  }, [getXp]);

  function renderTechnologyStackCard(item) {
    return (
      <div key={item.name} className="card" data-inviewport="scale-in">
        <h4>{item.name}</h4>
        <img src={item.logo} alt={item.name} />
      </div>
    );
  }

  return (
    <>
      <section className="headline">
        <div className="container grid">
          <div className="headline-text">
            <h2>Michael Jarvis</h2>
            <h1>Software Developer</h1>
            <p>
              Self-starter with <TimeSinceReadout startTime={startDate} /> of
              experience. Proficient in a wide range of{" "}
              <a href="#languages" className="languages-link">
                front-end and back-end technologies
              </a>
              . Adaptive learner with an unquenchable thirst for knowledge.
              Particular skills include learning new languages and paradigms
              quickly, getting to grips with unfamiliar code-bases, problem
              solving, plate spinning. Key qualities: Creative, Tenacious and
              Uncompromising.
            </p>

            <a href="#fullstack" className="btn btn-outline">
              Read More
            </a>
          </div>
          <div className="contact-form card flex-col">
            <h2>Request a meeting</h2>
            {isSubmitting ? <div className="flex flex-center">
              <div><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
            <div className="lds-ring lds-ring-dark">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div> : <form onSubmit={handleSubmit}>
              <div className="form-control">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  onChange={handleChange}
                  value={formData.name}
                  required
                />
              </div>
              <div className="form-control">
                <input
                  type="text"
                  name="company"
                  placeholder="Company Name"
                  onChange={handleChange}
                  value={formData.company}
                  required
                />
              </div>
              <div className="form-control">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  value={formData.email}
                  required
                />
              </div>
              <div className="flex">
                <input
                  type="submit"
                  className="btn btn-primary"
                  value="Send"
                  disabled={isSubmitting}
                />
              </div>
            </form>}
          </div>
        </div>
      </section>
      <section className="stats">
        <div className="container" id="stats">
          <h3 className="stats-heading text-center my-1">
            A committed coder with an optimistic approach
            {!isMobile ? <br /> : " "}
            and a love of caffeine.
          </h3>
          <div className="grid grid-3 text-center my-4">
            <div data-inviewport="slide-in-from-left">
              <FontAwesomeIcon icon={"coffee"} className="icon fa-3x" />
              <h3>{numberWithCommas(days * 5)}</h3>
              <p className="text-secondary">Coffees Drunk while Working</p>
            </div>
            <div data-inviewport="slide-in-from-left">
              <FontAwesomeIcon icon={"upload"} className="icon fa-3x" />
              <h3>{numberWithCommas(contributions)}</h3>
              <p className="text-secondary">
                GitHub Contributions
                <br />
                over the Last Year
              </p>
            </div>
            <div data-inviewport="slide-in-from-left">
              <FontAwesomeIcon icon={"bug"} className="icon fa-3x" />
              <h3>{numberWithCommas(bugs)}</h3>
              <p className="text-secondary">Bugs Fixed*</p>
            </div>
          </div>
          <p className="disclaimer text-center">
            *While the last one is a whimsical fabrication, the other two values
            are accurate.
          </p>
        </div>
      </section>
      <section className="package my-3">
        <div className="container grid">
          <div className="code-block">
                        <br />
            <code>$ pip install fast-auth-tools</code>
            <br />
            <br />
            <code>$ pip install domains-api</code>
            <br />
            <br />

          </div>
          <div className="card text-center">
            <h3>
              <span className="md-hidden">&#128070;</span>
              <span className="sm-hidden">&#128072;</span> Check out my{" "}
              <a href="https://pypi.org/user/mjfullstack/" target="_blank" rel="noreferrer">packages</a>
            </h3>
          </div>
          <div className="card text-center">
            <h3>
              ...and my <a href="#languages">stack</a> &#128071;
            </h3>
          </div>
        </div>
      </section>
      <section className="fullstack bg-grad my-2 py-2" id="fullstack">
        <div className="container grid grid-align-centre">
          <div className="text-center">
            <h2 className="lg">Full Stack Capabilities</h2>
            <p className="lead my-1">
              Clean, modular solutions.
              <br />
              From concept to deployment.
              <br />
              Fast, efficient and scalable systems.
            </p>
            <Link to="/portfolio" className="btn btn-dark">
              See Portfolio
            </Link>
          </div>
          <img
            src={cloud}
            alt=""
            data-inviewport="scale-in"
            className="cloud-img"
          />
        </div>
      </section>
      <section className="languages text-center text-dark" id="languages">
        <h2 className="md text-center my-2">Languages & Frameworks</h2>
        <div className="container flex max-width-60">
          {technologies.map(renderTechnologyStackCard)}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default LandingPage;
