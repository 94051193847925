import React, {useCallback, useEffect, useLayoutEffect} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import LandingPage from "./components/LandingPage";
import Portfolio from "./components/Portfolio";
import Content from "./components/Content";
import Navbar from "./components/Navbar";
import { REMOVE_ALL, useToastContext } from "./contexts/ToastContext";
import {initGA} from "./analytics";


function App() {
  useLayoutEffect(() => {
    initGA()
  }, []);

  const { toast, toastDispatch } = useToastContext();

  const removeAllToasts = useCallback(() => {
    if (toast.length > 0) {
      toastDispatch({
        type: REMOVE_ALL,
      });
    }
  }, [toast, toastDispatch]);

  useEffect(() => {
    const timeout = setTimeout(removeAllToasts, 5000);
    return () => clearTimeout(timeout);
  }, [removeAllToasts]);

  return (
    <Router>
      <div className="App">
        <Navbar />
        <Switch>
          <Route path="/portfolio">
            <Portfolio />
          </Route>
          <Route path="/content">
            <Content />
          </Route>
          <Route
            path="/github"
            component={() => {
              window.location.href = "https://github.com/nihilok";
              return null;
            }}
          />
          <Route
            path="/pypi"
            component={() => {
              window.location.href = "https://pypi.org/project/domains-api";
              return null;
            }}
          />
          <Route path="/home">
            <LandingPage />
          </Route>
          <Route component={LandingPage} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
