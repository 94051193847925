import React from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Footer = () => {
    return (
        <footer className="footer bg-dark py-5 flex z-index-top align-center" id="footer">
            <div className="container grid grid-4 align-center">
                <div className="copyright">
                    <h1>MJFullStack</h1>
                    <p>Copyright &copy; 2021</p>
                    <p className="xs">Built with Python/React.js</p>
                </div>
                <nav>
                    <ul>
                        <li><Link to="/home">Home</Link></li>
                        <li><Link to="/portfolio">Portfolio</Link></li>
                        <li><Link to="/content">Content</Link></li>
                        <li><a href="#top">Back to top</a></li>
                    </ul>
                </nav>
                <div className="social">
                    <a href="https://github.com/nihilok"><FontAwesomeIcon icon={['fab', 'github']} className="fa-2x"/></a>
                    <a href="https://www.linkedin.com/in/mjfullstack/"><FontAwesomeIcon icon={['fab', 'linkedin']} className="fa-2x"/></a>
                    <Link to="#"><FontAwesomeIcon icon={['fab', 'twitter']} className="fa-2x"/></Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;