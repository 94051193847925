import React, {useMemo} from 'react';
import {useToastContext, REMOVE} from '../contexts/ToastContext';

function ToastItem(props) {

    const {toastDispatch} = useToastContext();

    function renderItem(content) {
        if (typeof content === 'function') {
            return content();
        } else {
            return <pre>{JSON.stringify(content, null, 2)}</pre>;
        }
    }

    const onClose= () => toastDispatch({type: REMOVE, payload: {id: props.t.id}})

    const memo = useMemo(() => (<div
        className={`toast-container-item ${props.t.type ? props.t.type : ""}`}
    >
              <span
                  role="img"
                  aria-label="close toast"
                  className="toast-close"
                  onClick={onClose}
              >
                &times;
              </span>
        {renderItem(props.t.content)}
    </div>), [props]);
    return memo
}

export default function Toast({toast}) {
    return (
        <div className="toast">
            <div className="toast-container">
                {toast.map(t => {
                    return (
                        <ToastItem key={t.id} t={t}/>
                    );
                })}
            </div>
        </div>
    );
}