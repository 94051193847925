import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../logo.png";

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="container flex">
        <div className="logo md flex flex-center">
          <img src={logo} alt="logo" className="logo-img" />
          <span className="logo-text">FullStack</span>
        </div>
        <nav>
          <ul>
            <li>
              <NavLink activeClassName="active" to="/home">
                Home
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/portfolio">
                Portfolio
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/content">
                Content
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
