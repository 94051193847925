import React, { useEffect } from "react";

export function TimeSinceReadout({ startTime }) {
  const getXp = () => {
    const secondsStamp = Math.floor(
      (new Date(Date.now()).getTime() - new Date(startTime).getTime()) / 1000
    );
    let years = 0;
    let months = 0;
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let runningTotal = 0;

    let interval = secondsStamp / 31536000;
    if (interval > 1) {
      years = Math.floor(interval);
    }
    runningTotal += years * 31536000;
    interval = (secondsStamp - runningTotal) / 2592000;
    if (interval > 1) {
      months = Math.floor(interval);
    }
    runningTotal += months * 2592000;
    interval = (secondsStamp - runningTotal) / 86400;
    if (interval > 1) {
      days = Math.floor(interval);
    }
    runningTotal += days * 86400;
    interval = (secondsStamp - runningTotal) / 3600;
    if (interval > 1) {
      hours = Math.floor(interval);
    }
    runningTotal += hours * 3600;
    interval = (secondsStamp - runningTotal) / 60;
    if (interval > 1) {
      minutes = Math.floor(interval);
    }
    runningTotal += minutes * 60;
    interval = secondsStamp - runningTotal;
    if (interval > 1) {
      seconds = Math.floor(interval);
    }
    let returnString = "";

    if (years > 0) {
      returnString += `${years} year${years === 1 ? "" : "s"}`;
    }
    if (months > 0) {
      returnString += `, ${months} month${months === 1 ? "" : "s"}`;
    }
    if (days > 0) {
      returnString += `, ${days} day${days === 1 ? "" : "s"}`;
    }
    if (hours > 0) {
      returnString += `, ${hours} hour${hours === 1 ? "" : "s"}`;
    }
    if (minutes > 0) {
      returnString += `, ${minutes} minute${minutes === 1 ? "" : "s"}`;
    }
    returnString += ` and ${seconds} second${seconds === 1 ? "" : "s"}`;
    return returnString;
  };

  return <>{getXp()}</>;
}
