import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './LandingPage.css';
import './Navbar.css';
import './Animations.css';
import './Portfolio.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBug, faUpload, faCoffee } from '@fortawesome/free-solid-svg-icons'
import {ToastProvider} from "./contexts/ToastContext";

library.add(fab, faUpload, faCoffee, faBug)



ReactDOM.render(
  <React.StrictMode>
    <ToastProvider>
      <App />
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
