import python from "../images/logos/python.png";
import javascript from "../images/logos/JavaScript.png";
import django from "../images/logos/django.png";
import flask from "../images/logos/flask.png";
import redis from "../images/logos/redis.png";
import fastapi from "../images/logos/fastapi.png";
import postgres from "../images/logos/postgres.png";
import aws from "../images/logos/aws.png";
import docker from "../images/logos/docker.png";
import reactLogo from "../images/logos/react.png";
import rust from "../images/logos/rust.png"
import bash from "../images/logos/bash.png";

export const technologies = [
  {
    name: "Python",
    logo: python,
  },
  {
    name: "JS",
    logo: javascript,
  },
  {name: "Rust", logo: rust},
  {name: "Bash", logo: bash},
  {
    name: "React",
    logo: reactLogo,
  },
  {
    name: "Django",
    logo: django,
  },
  {
    name: "Flask",
    logo: flask,
  },
  {
    name: "FastAPI",
    logo: fastapi,
  },
  {
    name: "Postgres",
    logo: postgres,
  },
  {
    name: "Redis",
    logo: redis,
  },
  {
    name: "Docker",
    logo: docker,
  },
  {
    name: "AWS",
    logo: aws,
  }

];
