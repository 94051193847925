import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import Footer from "./Footer";
import { logPageView } from "../analytics";

const Portfolio = () => {

  useLayoutEffect(() => {
    logPageView("Portfolio");
  }, []);

  const [portfolio, setPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);
  const scrollRef = useRef(null);

  async function GetItems() {
    setLoading(true);
    let cacheExpires = localStorage.getItem("portfolio-cache-expires");
    const localCache = localStorage.getItem("portfolio-data");
    if (cacheExpires) {
      const now = new Date();
      const then = new Date(Date.parse(cacheExpires));
      if (now.getTime() - then.getTime() > 10800000) {
        localStorage.clear();
      } else {
        if (localCache) {
          setPortfolio(JSON.parse(localCache));
          setLoading(false);
          return;
        }
      }
    }

    await fetch("https://portfolio.jarv.dev/portfolio/all/").then((res) =>
      res.json().then((data) => {
        setPortfolio(data);
        localStorage.setItem("portfolio-data", JSON.stringify(data));
        localStorage.setItem(
          "portfolio-cache-expires",
          JSON.stringify(Date.now())
        );
        setLoading(false);
      })
    );
  }

  // const scrollHandler = () => {
  //     let offset = parseFloat(window.pageYOffset / document.body.scrollHeight * 100).toFixed(2)
  //     setOffsetY(offset)
  // }

  useEffect(() => {
    GetItems();
    window.scrollTo(0, 0);
    // window.addEventListener('scroll', scrollHandler)
    // return () => {
    // window.removeEventListener('scroll', scrollHandler)
    // }
  }, []);

  // useScrollSnap({ ref: scrollRef, duration: 100, delay: 50 });

  return (
    <>
      <section className="arrow-section" style={{}}>
        <div className="arrow-down" />
        <div className="container flex flex-center">
          <div className="top-text text-center">
            <h1 className="lg">My Public Projects</h1>
            <p>Check out my work. Feel free to contribute!</p>
          </div>
        </div>
      </section>
      <section className="portfolio-cards">
        {loading ? (
          <div className="flex flex-center">
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        ) : (
          <div className="container flex" ref={scrollRef}>
            {portfolio.map((item, index) => {
              const image = item.image;
              return (
                <div className="card portfolio-card my-2" key={index}>
                  <img src={image} alt="" />
                  <div className="card-body">
                    <h1>{item.project_name}</h1>
                    <p>{item.description}</p>
                  </div>
                  <div className="card-action">
                    <a
                      href={item.code_url}
                      className="btn btn-outline bg-grad"
                    >
                      Code
                    </a>
                    <a href={item.demo_url} className="btn btn-outline bg-grad">
                      Preview
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
      <Footer />
    </>
  );
};

export default Portfolio;
